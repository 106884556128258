import React from "react"
import { Link } from "gatsby"
import { Flex, Title, Grid, Box } from "../system"
import { GatsbyImage } from "gatsby-plugin-image"
import slugify from "slugify"

const Brands = ({ data }) => {
  return (
    <Flex flexDirection="column" p={6} bg="white" color="black">
      <Title fontWeight="normal">Brands</Title>
      <Grid
        gridGap={[10, 80]}
        gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
        width={"90%"}
        style={{ alignItems: "center" }}
      >
        {data.map((brand, key) => (
          <Box width="100%" key={key}>
            <Link to={`/shop/brand/${slugify(brand.name, { lower: true })}`}>
              <GatsbyImage
                key={key}
                image={brand.logo.asset.gatsbyImageData}
                alt={brand.name}
              />
            </Link>
          </Box>
        ))}
      </Grid>
    </Flex>
  )
}

export default Brands
