import React from "react"
import { Link } from "gatsby"
import { Flex, Label, Text, Grid, Title, Button } from "../system"
import slugify from "slugify"

import Product from "../Product"

const Featured = ({ data }) => {
  const { name, bio, products } = data
  return (
    <Flex
      bg="white"
      color="black"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={[5, 6]}
    >
      <Flex flexDirection="column">
        <Label color="faded">Featured Brand</Label>
        <Title fontSize={[5, 6]} mb={2}>
          {name}
        </Title>
        <Text px={[3, 5, "25%"]} mb={4} color="black">
          {bio}
        </Text>
      </Flex>
      <Grid
        gridGap={[50, 30, 80]}
        gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
        mx={5}
        my={4}
      >
        {products.map((product, key) => (
          <Product data={product} key={key} />
        ))}
      </Grid>
      <Link to={`/shop/brand/${slugify(name, { lower: true })}`}>
        <Button outline color="black" hbg="black" hcolor="white">
          View Collection ->
        </Button>
      </Link>
    </Flex>
  )
}
export default Featured
