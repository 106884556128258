import React from "react"
import { Link } from "gatsby"
import { Flex, Title, Button } from "../system"
import { Mark } from "../Icons"

const Tagline = ({ data }) => {
  return (
    <Flex
      bg="primary"
      py={[5, 6]}
      px={[4, 5, 6, 7]}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Mark color="white" />
      <Title
        fontSize={[5, 6, 6]}
        mt={3}
        mb={4}
        fontWeight="normal"
        color="white"
      >
        {data.text}
      </Title>
      <Link to={data.link}>
        <Button bg="#ED6E49" color="white">
          {data.button} ->
        </Button>
      </Link>
    </Flex>
  )
}

export default Tagline
