import React from "react"
import { Link } from "gatsby"
import { Flex, Title, Text, Box, Grid, Button } from "../system"

const Community = ({ data }) => {
  const { title, text, image, icons } = data
  return (
    <Flex flexDirection="column" py={[5, 6]} bg="secondary" color="white">
      <Title fontWeight="normal" fontSize={[5, 6]}>
        {title}
      </Title>
      <Text px={[4, "25%"]} color="rgba(255,255,255,0.75)">
        {text}
      </Text>
      <Grid
        gridGap={[4, 6]}
        gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
        mt={5}
      >
        {icons.map((icon, key) => (
          <Flex flexDirection="column" justifyContent="flex-start" key={key}>
            <img alt="icon" src={icon.image.asset.url} />
            <Text color="rgba(255,255,255,0.75)">{icon.text}</Text>
          </Flex>
        ))}
      </Grid>
      <Box mt={5}>
        <Link to="/join">
          <Button bg="primary" color="white" hbg="w" hcolor="white">
            Find out more ->
          </Button>
        </Link>
      </Box>
    </Flex>
  )
}

export default Community
