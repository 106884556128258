import React, { useState } from "react"
import { Flex, Title, Text, Input } from "../system"

const Subscribe = ({ title, text, button }) => {
  const [email, setEmail] = useState("")
  return (
    <Flex flexDirection="column" bg="#5C4030" color="white" p={[5, 5, 6]}>
      <Title mb={2} fontSize={[5, 6]}>
        {title}
      </Title>
      <Text px={[3, 5, "25%"]} mb={4} color="#CECECE">
        {text}
      </Text>

      <form
        action="https://ilana.us2.list-manage.com/subscribe/post?u=6645f666c2250961879896a1b&amp;id=92bf04e2d7"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate
      >
        <Flex width={["90vw", "60vw", "40vw"]} flexWrap="wrap">
          <Input
            p={3}
            width={["100%", "65%"]}
            type="email"
            value={email}
            name="EMAIL"
            id="mce-EMAIL"
            placeholder="Email Address"
            onChange={e => setEmail(e.target.value)}
            required
            borderRadius={3}
            border="none"
          />

          <Input
            type="submit"
            value={button ? button : "Subscribe"}
            name="subscribe"
            id="mc-embedded-subscribe"
            ml={[0, 3]}
            mt={[3, 0]}
            p={3}
            width={["90vw", 120]}
            minWidth={120}
            height={60}
            bg="#ED6E49"
            color="white"
            border="none"
            borderRadius={3}
          />
        </Flex>
      </form>
    </Flex>
  )
}

export default Subscribe
