import React, { useState, useEffect } from "react"
import { Link } from "@reach/router"
import { Title, Button, Flex, Box } from "../system"
import { motion } from "framer-motion"
import { GatsbyImage } from "gatsby-plugin-image"

const variants = {
  loaded: { backgroundColor: "rgba(0,0,0,0.2)" },
  loading: { backgroundColor: "rgba(0,0,0,1)" },
}

const Hero = ({ title, hero }) => {
  const [loaded, loading] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      loading(true)
    }, 1000)
    return () => clearTimeout(timer)
  })

  return (
    <Box>
      <FadeIn
        animate={loaded ? "loaded" : "loading"}
        initial="loading"
        variants={variants}
        transition={{ duration: 1 }}
        flexDirection="column"
        height="100vh"
      >
        <GatsbyImage
          alt={title}
          image={hero.asset.gatsbyImageData}
          style={{ position: "absolute", top: 0, zIndex: -1, height: "100vh" }}
        />
        <Title fontSize={[6, 6, 8]} color="white" px={[2, 6, 7]}>
          {title}
        </Title>
        <Flex>
          <Link to="/shop/men">
            <Button to="/shop" mr={4} bg="white" width={130}>
              Men
            </Button>
          </Link>
          <Link to="/shop/women">
            <Button to="/shop" bg="white" width={130}>
              Women
            </Button>
          </Link>
        </Flex>
      </FadeIn>
    </Box>
  )
}

export default Hero

const FadeIn = motion.custom(Flex)
