import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Hero from "../components/Hero"
import Tagline from "../components/Tagline"
import Featured from "../components/Featured"
import Video from "../components/Video"
import Community from "../components/Join"
import Brands from "../components/Brands"
import Subscribe from "../components/Subscribe"

const Home = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      sanitySeo {
        title
        description
        image {
          asset {
            gatsbyImageData
            url
          }
        }
      }
      sanityHome {
        title
        hero {
          asset {
            gatsbyImageData
          }
        }
        tagline {
          text
          button
          link
        }
        brand {
          name
          bio
          products {
            name
            slug {
              current
            }
            brand {
              name
            }
            price
            images {
              asset {
                gatsbyImageData(aspectRatio: 0.8)
              }
            }
          }
        }
        video {
          label
          title
          link {
            asset {
              url
            }
          }
        }
        community {
          title
          text
          icons {
            text
            image {
              asset {
                url
              }
            }
          }
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        brands {
          name
          logo {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  const { title, hero, brands, tagline, video, brand, community } =
    data.sanityHome

  return (
    <Layout>
      <Seo
        title={data.sanitySeo.title}
        description={data.sanitySeo.description}
        image={data.sanitySeo.image.asset.url}
      />
      <Hero hero={hero} title={title} />
      <Tagline data={tagline} />
      <Featured data={brand} />
      <Video
        label={video.label}
        title={video.title}
        url={video.link.asset.url}
      />
      <Community data={community} />
      <Brands data={brands} />
      <Subscribe
        title="Be the first to know"
        text="Sign up to our newsletter to keep up to date with new product releases,
        collaborations and much more."
      />
    </Layout>
  )
}

export default Home
